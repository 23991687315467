import React from 'react';

export default function SeatgeekSVG() {
  return (
    <svg viewBox="20 554 299 228" width="24" height="24">
      <g>
        <path d="M71.3,578.6c0,0,37.5-24.6,98.2-24.6s98.2,24.6,98.2,24.6l-24.6,130.4H95.8L71.3,578.6z" />
        <path d="M71.3,737.8c0,0,38.2,17.5,98.2,17.5s98.2-17.5,98.2-17.5v27.8c0,0-38.9,16.4-98.2,16.4 s-98.2-16.4-98.2-16.4V737.8z" />
        <path d="M69.1,719.8v-46.4c0-7.5-6.1-13.6-13.6-13.6H20v24.6h22.9v35.5H69.1z" />
        <path d="M269.9,719.8v-46.4c0-7.5,6.1-13.6,13.6-13.6H319v24.6H296v35.5H269.9z" />
      </g>
    </svg>
  );
}
